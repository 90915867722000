
	import { Component, Inject, InjectReactive, Prop, Vue, Watch } from 'vue-property-decorator';
	import { mapState } from 'vuex';
	import BodyText from '@pixcap/ui-library/components/Typography/BodyText.vue';
	import { IAssetsLibraryState, LibraryQueryTypes, NAMESPACE as LIBRARY_NAMESPACE, SEARCH_ITEM_TYPE } from '@/models/store/pixcapassets.interface';
	import { LIBRARY_RESOURCE_TYPE } from '@pixcap/ui-types/enums/library.enums';
	import { actionsWrapper as LibraryActions } from '@/store/pixcapassets/wrapper';
	import PixcapLibraryPack from '@/components/pixcap-library/shared/PixcapLibraryPack.vue';
	import ExploreLibraryEmptyState from '@/components/pixcap-library/shared/ExploreFilesEmptyState.vue';
	import LibraryItemListLoader from '@/components/pixcap-library/shared/LibraryItemListLoader.vue';
	import { setupBaseMetaTags } from '@/utils/seo';
	import { capitaliseString } from '@/utils/StringUtils';
	import Pagination from '@/components/pixcap-library/shared/Pagination.vue';
	import { LIBRARY_EXPLORER_RESOURCE_TYPES } from '@/constants/pixcapassets.constants';
	import { addQueryParam, removeUrlParameter } from '@/utils/route';
	import { ArrayIsEquals } from '@/utils/ArraysUtils';
	import { IOrganismState, NAMESPACE as ORGANISM_NAMESPACE } from '@/models/store/organism.interface';

	@Component({
		name: 'PackItemList',
		components: {
			Pagination,
			BodyText,
			PixcapLibraryPack,
			ExploreLibraryEmptyState,
			LibraryItemListLoader,
		},
		computed: {
			...mapState(ORGANISM_NAMESPACE, {
				isSearchPageFilterExpanded: (state: IOrganismState) => state.isSearchPageFilterExpanded,
			}),
			...mapState(LIBRARY_NAMESPACE, {
				activeSearchItemType: (state: IAssetsLibraryState) => state.activeSearchItemType,

				isFetchingPacks: (state: IAssetsLibraryState) => state.isFetchingPacks,
				listOfItems: (state: IAssetsLibraryState) => state.libraryPacksState.listOfItems,
				totalPages: (state: IAssetsLibraryState) => state.libraryPacksState.totalPages,
				totalItems: (state: IAssetsLibraryState) => state.libraryPacksState.totalItems,
				currentPage: (state: IAssetsLibraryState) => state.libraryPacksState.currentPage,
				pageSize: (state: IAssetsLibraryState) => state.libraryPacksState.pageSize,
				librarySearch: (state: IAssetsLibraryState) => state.librarySearch,
				showOnlyProPacks: (state: IAssetsLibraryState) => state.showOnlyProPacks,
				showOnlyAnimatedFiles: (state: IAssetsLibraryState) => state.showOnlyAnimatedFiles,
				packFilter: (state: IAssetsLibraryState) => state.packFilter,
			}),
		},
	})
	export default class PackItemList extends Vue {
		@Prop({ default: false }) readonly ignoreMetaHeadSetup: boolean;
		@Prop({ default: false }) isBrandKit: boolean;

		@Inject() handleScrollBack: (isSmoothBehavior: boolean, offsetTop: number) => void;
		@InjectReactive() windowInnerWidth: number;

		isSearchPageFilterExpanded: IOrganismState['isSearchPageFilterExpanded'];

		activeSearchItemType: IAssetsLibraryState['activeSearchItemType'];

		isFetchingPacks: IAssetsLibraryState['isFetchingPacks'];
		listOfItems: IAssetsLibraryState['libraryPacksState']['listOfItems'];
		totalPages: IAssetsLibraryState['libraryPacksState']['totalPages'];
		totalItems: IAssetsLibraryState['libraryPacksState']['totalItems'];
		currentPage: IAssetsLibraryState['libraryPacksState']['currentPage'];
		pageSize: IAssetsLibraryState['libraryPacksState']['pageSize'];
		librarySearch: IAssetsLibraryState['librarySearch'];
		showOnlyProPacks: IAssetsLibraryState['showOnlyProPacks'];
		showOnlyAnimatedFiles: IAssetsLibraryState['showOnlyAnimatedFiles'];
		packFilter: IAssetsLibraryState['packFilter'];

		previousItemCount = 0;
		isScrollEnabled = false;
		pageQuery = 0;
		defaultPageSize = 40;

		@Watch('totalItems')
		handleGetPreviousValue(_newVal, oldVal) {
			if (oldVal) {
				this.previousItemCount = oldVal;
			}
		}

		@Watch('librarySearch')
		@Watch('packFilter.tagSearch')
		@Watch('showOnlyProPacks')
		@Watch('showOnlyAnimatedFiles')
		resetRequest() {
			this.pageQuery = 0;
			this.initLibraryFetchRequest();
		}

		@Watch('numberOfPackPerPage')
		changePageSize() {
			if (this.defaultPageSize !== this.numberOfPackPerPage) {
				this.initLibraryFetchRequest();
			}
		}

		@Watch('packFilter.resourceTypes')
		onResourceTypeChange(newVal, oldVal) {
			if (ArrayIsEquals(newVal, oldVal)) {
				return;
			}
			this.pageQuery = 0;
			this.initLibraryFetchRequest();
		}

		@Watch('pageQuery')
		handlePageQuery(value) {
			if (value > 0) {
				addQueryParam(LibraryQueryTypes.PAGE, value + 1, true);
			} else {
				removeUrlParameter(LibraryQueryTypes.PAGE, true);
			}
		}

		get listOfPacks() {
			return this.listOfItems;
		}

		get shouldScrollUp() {
			return this.$route.name === 'LibraryPage';
		}

		get showExpandList() {
			return this.$route.name === 'LibraryExploreSearchList' && !this.isSearchPageFilterExpanded;
		}

		get packResourceType() {
			if (this.librarySearch) {
				let resourceTypes: LIBRARY_RESOURCE_TYPE[];
				switch (this.activeSearchItemType) {
					case SEARCH_ITEM_TYPE.THREE_D_ICONS:
						resourceTypes = [
							LIBRARY_RESOURCE_TYPE.MODELS,
							LIBRARY_RESOURCE_TYPE.FRAMES,
							LIBRARY_RESOURCE_TYPE.TEXT,
							LIBRARY_RESOURCE_TYPE.TEXT_SHAPES,
							LIBRARY_RESOURCE_TYPE.SHAPES,
						];
						break;
					case SEARCH_ITEM_TYPE.THREE_D_CHARACTERS:
						resourceTypes = [LIBRARY_RESOURCE_TYPE.CHARACTERS];
						break;
					case SEARCH_ITEM_TYPE.MOCKUPS:
						resourceTypes = [LIBRARY_RESOURCE_TYPE.DESIGN_TEMPLATES];
						break;
					case SEARCH_ITEM_TYPE.ALL_ASSETS:
					default:
						resourceTypes = LIBRARY_EXPLORER_RESOURCE_TYPES;
						break;
				}
				return resourceTypes;
			}
			return this.packFilter?.resourceTypes;
		}

		get numberOfPackPerPage() {
			if (this.windowInnerWidth === 0) return 40;
			if (this.windowInnerWidth <= 743) {
				return 30;
			} else if (this.windowInnerWidth <= 1024) {
				return 30;
			} else if (this.windowInnerWidth >= 1025 && window.innerWidth <= 1440) {
				return 30;
			} else if (this.windowInnerWidth > 1440 && window.innerWidth < 1920) {
				return 40;
			} else {
				return 40;
			}
		}

		get isInSearchPage() {
			return this.$route.name === 'LibraryExploreSearchList';
		}

		async fetch() {
			this.pageQuery = Number(this.$route.query[LibraryQueryTypes.PAGE] as string) - 1 || 0;
			await this.initLibraryFetchRequest();
		}

		head() {
			if (this.librarySearch || this.ignoreMetaHeadSetup) return;

			const headMeta: any = {
				meta: [],
				script: [],
			};
			const metaTagsPaylod: any = {
				url: `${process.env.ROOT_URL}${this.$route.fullPath}`,
			};
			const searchWord = this.librarySearch;
			if (searchWord) {
				metaTagsPaylod.title = `${this.isFetchingPacks ? this.previousItemCount : this.totalItems} ${capitaliseString(
					searchWord
				)} 3D Icon Packs - Free Download PNG, GLB`;
				metaTagsPaylod.description = `Customize & download ${searchWord} 3D icon pack ✓ Free license for commercial use ✓ New packs added everyday.`;
				this.listOfItems.forEach((item) => {
					headMeta.script.push({
						json: {
							'@context': 'http://schema.org',
							'@type': 'ImageObject',
							name: item.packName,
							url: `${process.env.ROOT_URL}/pack/${item.slug}`,
							image: item.thumbnails?.[item.thumbnails.length - 1]?.thumbnailUrl,
							description: `${item.packName} 3D Icon Packs`,
						},
						type: 'application/ld+json',
						'data-test': 'image-object',
					});
				});
			} else {
				metaTagsPaylod.title = `Customizable 3D Icon Packs - Free download PNG, GLB`;
				metaTagsPaylod.description = `3D icon packs with customizable 3D graphics for any theme ➤ Download now in PNG, GLB format`;
			}
			setupBaseMetaTags(metaTagsPaylod, headMeta, this.$route);
			return headMeta;
		}

		handleSelectPage(page: number) {
			this.handleScrollBack(true, this.isInSearchPage ? 0 : 476);
			this.pageQuery = page;
			LibraryActions.fetchPacksList(this.$store, {
				refresh: true,
				page,
				pageSize: this.numberOfPackPerPage,
				search: this.librarySearch,
				isPro: this.showOnlyProPacks,
				hasAnimated: this.activeSearchItemType === SEARCH_ITEM_TYPE.THREE_D_CHARACTERS ? null : this.showOnlyAnimatedFiles,
				isBrandKit: this.isBrandKit,
				resourceTypes: this.packResourceType,
				isCurated: this.packFilter?.tagSearch === 'trending' ? true : undefined,
				tagSearch: this.packFilter?.tagSearch === 'trending' ? undefined : this.packFilter?.tagSearch,
			});
		}

		async fetchLibraryPacks(refresh: boolean) {
			if (this.isFetchingPacks) return;

			if (refresh && process.client && this.shouldScrollUp) {
				if (this.isScrollEnabled) this.handleScrollBack(true, this.isInSearchPage ? 0 : 476);
				// Shouldn't scroll on first load
				if (!this.isScrollEnabled) {
					this.handleScrollBack(false, 0);
					this.isScrollEnabled = true;
				}
			}
			await LibraryActions.fetchPacksList(this.$store, {
				refresh,
				page: this.pageQuery,
				pageSize: this.numberOfPackPerPage,
				search: this.librarySearch,
				isPro: this.showOnlyProPacks,
				hasAnimated: this.activeSearchItemType === SEARCH_ITEM_TYPE.THREE_D_CHARACTERS ? null : this.showOnlyAnimatedFiles,
				isBrandKit: this.isBrandKit,
				resourceTypes: this.packResourceType,
				isCurated: this.packFilter?.tagSearch === 'trending' ? true : undefined,
				tagSearch: this.packFilter?.tagSearch === 'trending' ? undefined : this.packFilter?.tagSearch,
			});
		}

		async initLibraryFetchRequest() {
			await this.fetchLibraryPacks(true);
		}
	}
